import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Link from "next/link";
import {useRouter} from "next/router";
import LangSwitcher from "components/LangSwitcher";
import MainNav from "components/MainNav";
import {state} from "state";
import {useSnapshot} from "valtio";

export default function Header(){
	const snap = useSnapshot(state);
	const {locale} = useRouter()
	const data = {...require(`localeComponents/header/${locale}.json`)}
	return (
		<Wrapper>
			<CenterBlock>
				<div className="logo">
					<Link href="/"><img src="/pic/logo.svg" alt="BaycTron"/></Link>
				</div>
				{/*<MainNav/>*/}
				<div className="header_sidebar">
					<LangSwitcher/>
					<Link href="/mint" className="mint_button">{data.mint_mutant_link}</Link>
					{/*<button onClick={() => state.mobileNav = !snap.mobileNav} className="mob_button">*/}
					{/*	{snap.mobileNav ? (*/}
					{/*		<img src="/pic/mobile-close.svg" alt="Mobile close"/>*/}
					{/*	) : (*/}
					{/*		<img src="/pic/mobile-nav.svg" alt="Mobile Nav"/>*/}
					{/*	)}*/}
					{/*</button>*/}
				</div>
			</CenterBlock>
		</Wrapper>
	)
}

const Wrapper = styled.header`
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	background: rgba(0, 0, 1, 0.7);
	backdrop-filter: blur(40px);
	.center_block{
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 80px;
	}
	.mob_button{
		width: 24px;
		height: 24px;
		align-items: center;
		justify-content: center;
		line-height: 0;
		margin-left: 10px;
		display: none;
		@media only screen and (max-width: 1024px) {
			display: flex;
		}
	}
	.main_nav{
		display: flex;
		@media only screen and (max-width: 1024px) {
			display: none;
		}
		li{
			margin-right: 60px;
			font-size: 18px;
			line-height: 140%;
			color: #ffffff;
			position: relative;
			@media only screen and (max-width: 1140px) {
				margin-right: 30px;
			}
			&.active a{
				color: #E2EE44;
			}
			&:last-child{
				margin-right: 0;
			}
		}
		.title{
			cursor: default;
			color: rgba(254, 254, 254, 0.5);
		}
		.soon{
			position: absolute;
			top: -15px;
			right: -20px;
			background: #FFC700;
			border-radius: 8px 8px 8px 0;
			font-size: 12px;
			color: #000;
			width: 45px;
			font-weight: 500;
			height: 17px;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		a{
			color: #ffffff;
			&:hover{
				color: #E2EE44;
			}
		}
	}
	.header_sidebar{
		display: flex;
		align-items: center;
	}
	.mint_button{
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		background: #E2EE44;
		border-radius: 16px;
		font-weight: 500;
		font-size: 18px;
		line-height: 140%;
		color: #000000;
		padding: 20px 35px;
		height: 60px;
		margin-left: 16px;
		@media only screen and (max-width: 600px) {
			display: none;
		}
	}
	.logo{
		@media only screen and (max-width: 600px) {
			width: 150px;
		}
	}
`
