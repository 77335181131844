import styled from "styled-components";

export default function Social(){
	return (
		<Wrapper className="social">
			<ul>
				<li><a rel="noreferrer" href="https://twitter.com/bayctron" target="_blank"><img src="/pic/social/twitter.svg" alt="Twitter"/></a></li>
				<li><a rel="noreferrer" href="https://t.me/BAYCTron" target="_blank"><img src="/pic/social/telegram.svg" alt="Telegram"/></a></li>
				<li><a rel="noreferrer" href="https://discord.gg/xWMYU8BfHu" target="_blank"><img src="/pic/social/discord.svg" alt="Discord"/></a></li>
			</ul>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	ul{
		display: flex;
		justify-content: center;
		li{
			margin: 0 5px;
			&:first-child{
				margin: 0 5px 0 0;
			}
			&:last-child{
				margin: 0 0 0 5px;
			}
		}
	}
`
